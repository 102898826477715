import Routes from 'src/constants/Routes';
import { checkIfSessionIsActive } from '@get-mosh/msh-shared';
import { useEffect, useState } from 'react';

type LoginLinkProps = {
    className?: string;
};

const LoginLink = ({ className }: LoginLinkProps) => {
    const [linkLabel, setLinkLabel] = useState('Login');
    const [linkPath, setLinkPath] = useState<
        ReturnType<typeof Routes.SIGN_IN> | typeof Routes.NOTIFICATIONS
    >(Routes.SIGN_IN());

    useEffect(() => {
        if (checkIfSessionIsActive()) {
            setLinkLabel('My account');
            setLinkPath(Routes.NOTIFICATIONS);
        }
    }, []);

    return (
        <a className={className} href={linkPath} title={linkLabel}>
            {linkLabel}
        </a>
    );
};

export default LoginLink;

